/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const CRISP_CHAT_SCRIPT = `
  window.$crisp = [];
  window.CRISP_WEBSITE_ID = "68318829-cb65-4e84-b5e1-222b10bc7b8a";
  (function () {
    d = document;
    s = d.createElement("script");
    s.src = "https://client.crisp.chat/l.js";
    s.async = 1;
    d.getElementsByTagName("head")[0].appendChild(s);
  })();
  `;

const GOOGLE_TAG_SCRIPT = `
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "GTM-WDFFWHZ");
  `;

const GOOGLE_TAG_SETUP_SCRIPT = `
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag("js", new Date());

  gtag("config", "UA-122496810-1", {
    'linker': {
      'domains': ['coach.thefitlab.io', 'client.thefitlab.io', 'thefitlab.io']
    }
  });
  `;

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author {
              name
              summary
            }
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      // titleTemplate={`${site.siteMetadata.title}`}
      script={[
        {
          type: "text/javascript",
          innerHTML: GOOGLE_TAG_SCRIPT,
        },
        {
          async: true,
          src: "https://www.googletagmanager.com/gtag/js?id=UA-122496810-1",
        },
        {
          type: "text/javascript",
          innerHTML: GOOGLE_TAG_SETUP_SCRIPT,
        },
        {
          type: "text/javascript",
          innerHTML: CRISP_CHAT_SCRIPT,
        },
      ]}
      link={[
        {
          rel: "stylesheet",
          href:
            "https://fonts.googleapis.com/css?family=Lato:normal,bold,italic|Fira+Sans:normal,bold,italic&display=swap",
        },
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `subject`,
          content: title,
        },
        {
          name: `application-name`,
          content: "FitLab",
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
