import React from "react";
import PropTypes from "prop-types";

import "../styles/layout.css";

const LayoutBase = ({ children }) => (
  <>
    {/* Google Tag Manager (noscript) */}
    <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-WDFFWHZ"
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
        title="gtm"
      />
    </noscript>
    {children}
  </>
);

LayoutBase.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutBase;
