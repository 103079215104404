import React from "react";

import Logo from "./Logo";

import styles from "./SimpleHeader.module.css";

const SimpleHeader = () => (
  <header className={styles.SimpleHeader}>
    <Logo />
  </header>
);

export default SimpleHeader;
