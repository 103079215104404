import React from "react";

import styles from "./SimpleFooter.module.css";

const SimpleFooter = () => (
  <footer className={styles.SimpleFooter} id="contacts">
    <div className={styles.SimpleFooterBottom}>
      <div className={styles.SimpleFooterCopyright}>
        Copyright &copy; 2021 FitLab, Inc. All Rights Reserved.
      </div>
    </div>
  </footer>
);

export default SimpleFooter;
