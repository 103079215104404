import React from "react";

import LayoutBase from "../components/LayoutBase";
import SimpleHeader from "../components/SimpleHeader";
import SimpleFooter from "../components/SimpleFooter";
import SEO from "../components/seo";

import styles from "./legal.module.css";

const Terms = () => (
  <LayoutBase>
    <SEO title="Terms - FitLab" />
    <SimpleHeader />
    <div className={styles.Legal}>
      <div>
        <h2>Terms And Conditions</h2>
        <div>
          <h2>1. Service Terms and Limitations</h2>
          <h3>1.1 Description</h3>
          <p>
            The Service is proprietary to FitLab and is protected by
            intellectual property laws and international intellectual property
            treaties. Your access to the Service is licensed and not sold.
            Subject to the timely payment of all Fees (as defined below) and the
            terms and limitations set forth in this Agreement, FitLab agrees to
            the following:
          </p>
          <p>
            (i) To provide you with (a) a personal, non-transferable and
            non-exclusive account enabling you to access and use the Service and
            (b) a limited, non-transferable and non-exclusive license to use the
            software and documentation necessary to access, explore and
            otherwise use FitLab, or sites powered by FitLab, in real time and
            to use the materials in FitLab, or sites powered by FitLab, in a
            manner consistent with paragraphs 4 (On-Line Communications) and 9
            (Copyright) below.
          </p>
          <p>
            (ii) To provide you with a limited, personal, non-transferable,
            nonsublicensable and non-exclusive license (a) to access and use
            FitLab&apos;s proprietary services and software.
          </p>
          <h3>1.2 User&apos;s Agreement Restrictions</h3>
          <p>
            (i) You agree to: (a) maintain the security of your user
            identification, password and other confidential information relating
            to your FitLab account; (b) be solely responsible for the security,
            confidentiality and integrity of all messages and the content that
            you receive, transmit through or store on the Service; (c) be
            responsible for all charges resulting from use of your FitLab
            account, including unauthorized use prior to your notifying FitLab
            in writing of such use and taking steps to prevent its further
            occurrence by changing your password; (d) comply with the terms and
            conditions set forth herein; and (e) comply with all applicable U.S.
            and international laws, statutes, ordinances, rules, regulations,
            contracts and applicable licenses regarding your use of the Services
            or FitLab.
          </p>
          <p>
            (ii) You may not, directly or indirectly, (a) modify, translate,
            reverse engineer, decompile, disassemble (except to the extent
            applicable laws specifically prohibit such restriction), create
            derivative works based on the Material; or (b) copy the Material or
            engage in any other acts inconsistent with the principles of
            copyright protection and fair use, as codified in 17 U.S.C. Sections
            106-110, without obtaining the express written permission of FitLab
            and the copyright owner; or (iii) distribute (except for the
            purposes set forth above), rent, lease, transfer or otherwise
            transfer rights to, or in any way exploit, the Material, in whole or
            in part; or (iv) remove any proprietary notices or labels on the
            Material. As between the parties, title, ownership rights, and
            intellectual property rights in and to the Material, and any copies
            or portions thereof, shall remain in Company and/or its content
            providers.
          </p>
          <h3>1.3 User Representations</h3>
          <p>
            You represent and warrant to FitLab that: (i) you are over the age
            of eighteen (18) and have the power and authority to enter into and
            perform your obligations under this Agreement; (ii) all information
            provided by you to FitLab is truthful, accurate and complete; (iii)
            you are the authorized signatory of the credit or charge card
            provided to FitLab to pay the Fees; (iv) you shall comply with all
            terms and conditions of this Agreement; and (v) you have provided
            and will provide accurate and complete registration information,
            including, without limitation, your legal name, address and
            telephone number. You agree to be financially responsible for your
            use of FitLab (as well as for use of your account by others,
            including minors living with you) and to comply with your
            responsibilities and obligations as stated in this Agreement.
          </p>
          <h3>1.4 Accessibility</h3>
          <p>
            You acknowledge and agree that from time to time the Service may be
            inaccessible or inoperable for any reason, including, without
            limitation: (i) equipment malfunctions; (ii) periodic maintenance
            procedures or repairs which FitLab may undertake from time to time;
            or (iii) causes beyond the control of FitLab or which are not
            reasonably foreseeable by FitLab.
          </p>
          <h3>1.5 Equipment</h3>
          <p>
            You shall be solely responsible for providing, maintaining and
            ensuring compatibility with the Service, all hardware, software,
            electrical and other physical requirements for your use of the
            Service, including, without limitation, telecommunications and
            internet access connections and links, web browsers or other
            equipment, programs and services required to access and use the
            Service.
          </p>
          <h2>2. Fees</h2>
          <p>
            FitLab charges a subscription fee (&quot;Fees&quot;) for the use of
            the Service. FitLab expressly reserves the right to change the Fees
            at any time, upon notice to you. By registering for a FitLab
            account, you agree to pay FitLab the Fees for the Service applicable
            to the account level chosen. The Fees for the Service do not include
            any payment for telecommunications and computer hardware, software,
            telecommunications access charges, lines or connections or other
            equipment and services required to access and use the Service. All
            Fees, Taxes (as defined herein) and other charges will be billed to
            your credit card at the current international currency conversion
            rate. You are responsible for and shall pay FitLab all currency
            conversion charges, sales, use, value-added, personal property or
            other tax, duty or levy of any kind, including interest and
            penalties thereon (collectively, &quot;Taxes&quot;) for the use of
            the Services or resulting from this Agreement, whether imposed now
            or hereinafter by any governmental entity. You agree to promptly pay
            FitLab in the event of any refusal of your credit card issuer to pay
            any amount to FitLab for any reason. On any outstanding balance, you
            agree to pay the costs of collection, including attorney&apos;s fees
            and costs. In the event you fail to pay any amount when due, FitLab
            may immediately suspend or terminate this Agreement and your access
            to the Service.
          </p>
          <h2>3. Disclaimer</h2>
          <h3>3.1 Content </h3>
          <p>
            The information presented or contained in FitLab, or provided
            through the Service (collectively, the &quot;Content&quot;) is
            presented for the purpose of educating athletes and coaches on
            fitness, general health, and related training issues and topics.
            Nothing contained in FitLab or the Content is intended to be
            instructional for medical diagnosis or treatment. The information
            should not be considered complete, nor should it be relied on to
            suggest a course of treatment for a particular individual. It should
            not be used in place of a visit, call, consultation or the advice of
            your physician or other qualified health care provider. Information
            obtained from FitLab is not exhaustive and does not cover diseases,
            ailments, physical conditions or their treatment. Should you have
            any health care related questions, please call or see your physician
            or other qualified health care provider promptly. Always consult
            with your physician or other qualified health care provider before
            embarking on a new treatment, diet or fitness program. You should
            never disregard medical advice or delay in seeking it because of
            something you have read on FitLab. Information contained at FitLab
            is for informational use only.
          </p>
          <p>
            The Content and other information contained in FitLab is compiled
            from a variety of sources (&quot;Information Providers&quot;).
            Neither FitLab nor any Information Providers directly or indirectly
            practice medicine or dispense medical services as part of FitLab.
            FitLab is not a substitution for the services of a physician, a
            health provider, or any trained health professional. Our policy does
            not allow medical advice, emotional advice, dietary advice, or
            personal advice of any kind. These concerns should be addressed and
            discussed with your physician, health care provider, family
            nutritionist or your registered dietitian.
          </p>
          <p>
            Adults should not commence any short or long-termed diet program
            without seeking competent medical advice.
          </p>
          <p>
            Infants, toddlers and teenagers should only participate in a diet
            program under competent medical supervision
          </p>
          <p>
            YOU ACKNOWLEDGE THAT FitLab IS NOT ENGAGED IN THE PRACTICE OF
            MEDICINE AND THAT ALL DECISIONS MADE WILL BE THE EXCLUSIVE
            RESPONSIBILITY OF YOU AND YOUR PHYSICIAN. FitLab EXPRESSLY DISCLAIMS
            ANY RESPONSIBILITY OR LIABILITY FOR ANY ADVERSE CONSEQUENCES OR
            DAMAGES RESULTING FROM YOUR RELIANCE ON FitLab, THE CONTENT OR ANY
            INFORMATION PROVIDED THEREFROM.
          </p>
          <h3>3.3 Fitness Disclaimer</h3>
          <p>
            The Content, including each article provided through the Service, is
            for entertainment purposes only. FitLab recommends that you consult
            a physician before beginning any exercise program or diet. FitLab is
            not a licensed medical care provider. You should understand that
            participating in any exercise program or diet can result in physical
            injury, and you agree to do so at your own risk. The findings and
            opinions of authors and personal trainers expressed herein are those
            of the author and do not necessarily state or reflect those of
            FitLab.
          </p>
          <h3>3.4 Disclaimer of Warranties</h3>
          <p>
            CONTENT ON OR AVAILABLE THROUGH THE SERVICE ARE PROVIDED &quot;AS
            IS&quot; WITHOUT WARRANTY, EXPRESS OR IMPLIED. FitLab HEREBY
            EXCLUDES ALL IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
            PARTICULAR USE OR PURPOSE WITH RESPECT TO THE SERVICE OR THE
            CONTENT. THERE ARE NO WARRANTIES WHICH EXTEND BEYOND THE DESCRIPTION
            ON THE FACE OF THIS AGREEMENT. FitLab AND INFORMATION PROVIDERS MAKE
            NO WARRANTY AS TO THE RELIABILITY, ACCURACY, TIMELINESS, USEFULNESS,
            ADEQUACY, COMPLETENESS OR SUITABILITY OF THE SERVICE. FitLab AND
            INFORMATION PROVIDERS CANNOT AND DO NOT WARRANT AGAINST HUMAN AND
            MACHINE ERRORS, OMISSIONS, DELAYS, INTERRUPTIONS OR LOSSES,
            INCLUDING LOSS OF DATA. FitLab AND INFORMATION PROVIDERS CANNOT AND
            DO NOT GUARANTEE OR WARRANT THAT FILES AVAILABLE FOR DOWNLOADING
            FROM THIS ONLINE SITE WILL BE FREE OF INFECTION BY VIRUSES, WORMS,
            TROJAN HORSES OR OTHER CODE THAT MANIFEST CONTAMINATING OR
            DESTRUCTIVE PROPERTIES. FitLab AND INFORMATION PROVIDERS DO NOT
            WARRANT OR GUARANTEE THAT THE FUNCTIONS OR SERVICE ACCESSED THROUGH
            FitLab WILL BE UNINTERRUPTED OR ERROR-FREE OR THAT DEFECTS IN FitLab
            WILL BE CORRECTED. Users of FitLab are responsible for (1)
            implementing and maintaining adequate procedures and checkpoints to
            satisfy their particular requirements for accuracy of data input and
            output and (2) maintaining a means external to FitLab for the
            reconstruction of any lost data.
          </p>
          <p>
            THIS DISCLAIMER OF WARRANTY CONSTITUTES AN ESSENTIAL PART OF THIS
            AGREEMENT.
          </p>
          <h3>3.5 Disclaimer of Third Party Information</h3>
          <p>
            Statements made in websites, newsgroups, message boards, email,
            forums, conferences and chats reflect only the views of their
            authors. Forum managers, forum hosts, content providers, Information
            Providers or merchants appearing on FitLab are not authorized FitLab
            spokespersons, and their views do not necessarily reflect those of
            FitLab.
          </p>
          <h2>4. On-Line Communications</h2>
          <h3>4.1</h3>
          <p>
            Your participation in on-line communications occurs in real time and
            is not edited, censored, or otherwise controlled by FitLab. FitLab
            cannot and does not screen content provided by you to FitLab or
            through the Service. Notwithstanding the foregoing, FitLab reserves
            the right to monitor content on FitLab and to remove content, which
            FitLab, in its sole discretion, determines to be harmful, offensive,
            or otherwise in violation of this Agreement or FitLab&apos;s
            operating policies for users of FitLab (&quot;Users&quot;). Except
            as described herein, any communication which you post on any public
            area of FitLab (e.g., comments, forums, etc.) is considered to be
            non-confidential. By placing any information or other material on
            such publicly accessible locations on FitLab (including but not
            limited to posting messages, uploading files, inputting data or
            engaging in any other form of communication), you automatically
            grant (or warrant that the owner of such content has expressly
            granted) to FitLab a perpetual, royalty- free, non-exclusive,
            irrevocable, unrestricted, worldwide license to use, copy,
            sublicense, reproduce, distribute, redistribute, modify, adapt,
            publish, edit, translate, transmit, create derivative works of,
            publish and/or broadcast, publicly perform or display any materials
            or other information (including without limitation, ideas contained
            therein for new or improved products or services) you submit to
            public areas of FitLab (including but not limited to chat rooms,
            forums and bulletin boards) alone or as part of other works in any
            form, media, or technology whether by any means and in any media now
            known or hereafter developed and to sublicense such rights through
            multiple tiers of sublicenses.
          </p>
          <h3>4.2</h3>
          <p>
            You may send and receive messages, electronic mail
            (&quot;email&quot;), engage in conferences and chats, download and
            upload files and otherwise use FitLab as permitted by this
            Agreement, FitLab operating policies and applicable law. Files that
            you upload, public messages that you send and your activity in
            conferences and chats are subject to review, modification and
            deletion without notice by the forum manager responsible for the
            FitLab area where you&apos;re uploading or other activity takes
            place (or by an individual designated by such forum manager for such
            purpose). FitLab operating policies relating to online conduct,
            storage and deletion of email and uploaded files, conferences, and
            bulletin boards (&quot;BBSs&quot;). FitLab reserves the right in its
            sole discretion to change such policies at any time. Files uploaded
            to a BBS may be subject to posted limitations on usage, reproduction
            and/or dissemination, and you are responsible for adhering to such
            limitations if you download them.
          </p>
          <h3>4.3 </h3>
          <p>
            You are responsible for your communications and your use of FitLab.
            You may not, under any circumstances, do any of the following: (a)
            use simultaneous, unattended or continuous connections to FitLab
            with one account; (b) post or transmit any message which is libelous
            or defamatory; (c) post or transmit any message, data, image or
            program which is indecent, obscene or pornographic; (d) post or
            transmit any message, data, image or program that would violate the
            property rights of others, including unauthorized copyrighted text,
            images or programs, trade secrets or other confidential proprietary
            information, and trademarks or service marks used in an infringing
            fashion; (e) use FitLab to threaten, harass, stalk, abuse, or
            otherwise violate the legal rights (including rights of privacy and
            publicity) of others; (f) intercept or attempt to intercept email or
            other private communications not intended for you; (g) send email to
            Users or other Internet users for any purpose other than personal
            communication, including to advertise or offer to unsolicited sell
            goods or services to other Users, use as a mass unsolicited
            distribution medium to communicate a generally unsolicited message,
            or use your email account as an address to which Users or other
            Internet users need to respond (except as otherwise expressly
            permitted by FitLab; (h) send unsolicited email messages through
            third-party mail servers in order to relay your email or hide the
            origination of your email to others; (i) upload or download files
            that contain software or other material protected by intellectual
            property laws, rights of privacy or publicity, or any other
            applicable law unless you own or control the rights to such files or
            have received all necessary consents; (j) upload files that contain
            a virus or corrupted data; (k) delete any author attributions, legal
            notices or proprietary designations or labels in a file that you
            upload to a BBS or FitLab; (l) falsify the source or origin of
            software or other material contained in a file that you upload to a
            BBS or FitLab; (m) use FitLab in a manner that adversely affects the
            availability of its resources to other Users; (n) use, download or
            otherwise copy, or provide (whether or not for a fee) to a person or
            entity that is not a subscriber to FitLab the User directory or any
            portion thereof other than for personal, noncommercial purposes
            (except as otherwise expressly permitted by FitLab; (o) falsely
            purport to be an employee or agent of FitLab (p) cause repeated
            disruptive incidents; (q) act, or fail to act, in your use of
            FitLab, in a manner that is contrary to applicable law or
            regulation; or (r) engage in any other activity deemed by the FitLab
            to be in conflict with the spirit or intent of this Agreement. In
            addition, you may not post or transmit any message which is harmful,
            threatening, abusive or hateful. It is not the intent of FitLab to
            discourage Users from taking controversial positions or expressing
            vigorously what may be unpopular views in FitLab, nonetheless,
            FitLab reserves the right to take such action as it deems
            appropriate in cases where FitLab is used to disseminate statements
            which are deeply and widely offensive and/or harmful. Each time you
            upload a file on FitLab, you represent and warrant that you own or
            otherwise control the rights or have the necessary consents to do
            so, and you grant every other User the right to download and use
            such file. Your failure to observe any of the foregoing limitations
            or obligations may result in civil or criminal liability.
          </p>
          <h2>5. Operation</h2>
          <p>
            FitLab reserves complete and sole discretion with respect to the
            operation of FitLab. FitLab may, among other things: (a) delete
            email or private messages if it has not been accessed by a User
            within the time established by FitLab policies; (b) subject to
            Section 7 and Section 4, make available to third parties information
            relating to FitLab and Users; and (c) withdraw, suspend or
            discontinue any functionality or feature of FitLab.
          </p>
          <p>
            FitLab may, in its complete and sole discretion, review uploaded
            files, conferences, BBSs, forums, and chats and authorize
            restrictions on access thereto. FitLab will not review the contents
            of email or private messages except as required or allowed by
            applicable law or legal process.
          </p>
          <h2>6. Other Sites </h2>
          <p>
            {" "}
            You are encouraged to use discretion while browsing the Internet
            using links initiated at FitLab. FitLab links may lead
            unintentionally to sites containing information that some people may
            find inappropriate or offensive. It may also lead to sites which
            contain inaccurate information, false or misleading advertising, or
            information which violates copyright, libel or defamation laws.
            FitLab and Information Providers make no representations concerning
            any effort to review all of the content of sites listed in its
            Website.
          </p>
          <h2>7. Review of Postings &amp; Uploads </h2>
          <p>
            FitLab does not and cannot review all communications and materials
            posted or uploaded to FitLab and is not responsible for the content
            of these communications and materials. However, FitLab reserves the
            right to block or remove communications or materials that it
            determines, in its sole discretion, to be (a) abusive, libelous,
            defamatory or obscene, (b) fraudulent, deceptive, or misleading, (c)
            in violation of a copyright or trademark, other intellectual
            property right of another or (d) offensive or otherwise unacceptable
            to FitLab. ANY MESSAGES, ADVICE, OPINIONS OR OTHER INFORMATION
            CONTAINED IN ANY DISCUSSION AREA SHOULD NOT BE CONSTRUED AS
            PROFESSIONAL ADVICE OR INSTRUCTION.
          </p>
          <h2>8. Copyright</h2>
          <p>
            The entire contents of FitLab are copyrighted as a collective work
            under the laws of the United States and other copyright laws. FitLab
            holds the copyright in the collective work. The collective work
            includes works that are the property of the Information Providers
            which are also protected by copyright and other intellectual
            property laws. You may display and, subject to any expressly stated
            restrictions or limitations relating to specific material, download
            portions of the material from the different areas of FitLab solely
            for your own non-commercial use, unless otherwise permitted (e.g.,
            in the case of electronic coupons, etc). Any redistribution
            retransmission or publication of any copyrighted material is
            strictly prohibited without the express written consent of the
            copyright owner. You agree not to change or delete any proprietary
            notices from materials downloaded from FitLab. You may not use any
            FitLab logo or any other proprietary graphic or trademark without
            FitLab&apos;s express written permission.
          </p>
          <h2>9. Licenses and Idea Submissions</h2>
          <p>
            You agree to grant to FitLab a non-exclusive, worldwide,
            royalty-free, perpetual license, with the right to sublicense, to
            reproduce, distribute, transmit, create derivative works of,
            publicly display and publicly perform any materials and other
            information (including, without limitation, ideas contained therein
            for new or improved products or services) you submit to public areas
            of FitLab (such as BBSs, forums and chat rooms) by all means and in
            any media now known or hereafter developed. You hereby waive your
            moral rights in any such materials and information, and you hereby
            warrant that any such materials and information are original with
            you, or that you have the right to submit such materials and
            information. You agree that you shall have no recourse against
            FitLab for any alleged or actual infringement or misappropriation of
            any proprietary right in your communication to us.
          </p>
          <h2>10. Indemnification</h2>
          <p>
            YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS FitLab, ITS
            OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, INFORMATION PROVIDERS AND
            SUPPLIERS FROM AND AGAINST ALL LOSSES, EXPENSES, DAMAGES AND COSTS,
            INCLUDING REASONABLE ATTORNEY&apos;S FEES, RESULTING FROM ANY
            VIOLATION OF THIS AGREEMENT OR ANY ACTIVITY RELATED TO YOUR ACCOUNT
            (INCLUDING INFRINGEMENT OF THIRD PARTIES&apos; WORLDWIDE
            INTELLECTUAL PROPERTY RIGHTS OR NEGLIGENT OR WRONGFUL CONDUCT) BY
            YOU OR ANY OTHER PERSON ACCESSING FitLab USING YOUR SERVICE ACCOUNT.
          </p>
          <h2>11. Waiver, Release and Limitation of Liability</h2>
          <p>
            YOU AGREE THAT NEITHER FitLab, NOR ITS OFFICERS, DIRECTORS,
            EMPLOYEES, AGENTS, INFORMATION PROVIDERS OR SUPPLIERS SHALL HAVE ANY
            LIABILITY TO YOU UNDER ANY THEORY OF LIABILITY OR INDEMNITY IN
            CONNECTION WITH YOUR USE OF FitLab, THE SERVICE, OR THE CONTENT. YOU
            HEREBY RELEASE AND FOREVER WAIVE ANY AND ALL CLAIMS YOU MAY HAVE
            AGAINST FitLab, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
            INFORMATION PROVIDERS OR SUPPLIERS (INCLUDING BUT NOT LIMITED TO
            CLAIMS BASED UPON THE NEGLIGENCE OF FitLab, ITS OFFICERS, DIRECTORS,
            EMPLOYEES, AGENTS, INFORMATION PROVIDERS OR SUPPLIERS) FOR LOSSES OR
            DAMAGES YOU SUSTAIN IN CONNECTION WITH YOUR USE OF FitLab, THE
            SERVICE, OR THE CONTENT.
          </p>
          <p>
            Your use of the Service is solely at your own risk. You expressly
            acknowledge and agree that FitLab shall not be responsible for any
            damage, loss, or injury to persons or property caused, or alleged to
            have been caused, directly or indirectly, by any instruction,
            infomration, product, or ideas provided, suggested or referenced on
            FitLab, the Service, or the Conent. No advice or information,
            whether oral or written, obtained by you from FitLab or through the
            Service shall create any warranty not expressly made herein.
          </p>
          <h2>12. Third Party Rights</h2>
          <p>
            The provisions of paragraphs 16 (Indemnification) and 17 (Waiver,
            Release and Limitation of Liability) are for the benefit of FitLab
            and its officers, directors, employees, agents, licensors,
            suppliers, and Information Providers. Each of these individuals or
            entities shall have the right to assert and enforce those provisions
            directly against you on its own behalf.
          </p>
          <h2>13. Term Termination</h2>
          <p>
            Membership to FitLab is available on a month-to-month or annual
            basis, with automatic renewal.{" "}
          </p>
          <p>
            Either you or FitLab may terminate your right to use FitLab at any
            time, with or without cause, upon notice. FitLab also reserves the
            right to terminate or suspend your FitLab membership without prior
            notice, but FitLab will confirm such termination or suspension by
            subsequent notice. The provisions of paragraphs 1.2 (User&apos;s
            Agreement), 1.3 (User Representations), 9 (Copyright), 10 (License,
            and Idea Submission), 11 (Indemnification), 12 (Waiver, Release and
            Limitation of Liability), 13 (Third Party Rights), 15
            (Cancellation), 17 (Miscellaneous), and 18 (Notice) shall survive
            any termination of this Agreement.
          </p>
          <h2>14. Cancellation</h2>
          <p>
            You are responsible for all associated renewal fees relating to your
            FitLab account until you elect to cancel your subscription to FitLab
            through your FitLab account.
          </p>
          <p>
            PLEASE NOTE: Notwithstanding anything to the contrary herein
            (including the provisions of Section 20 below), in the event you
            wish to cancel your subscription, (a) you must initiate the
            cancellation process through your FitLab account, and (b) you must
            complete such cancellation in accordance with the instructions
            provided herein.
          </p>
          <p>
            Cancellations are not retroactive therefore if a member cancels
            after their membership month has begun, the member will not be
            entitled to a refund for that membership month. Cancellations do not
            become effective until the starting date of the member&apos;s next
            membership billing cycle. A membership month begins on the calendar
            date when a member subscribes to FitLab and concludes on the day
            before that calendar date the following month.
          </p>
          <h2>15. Refunds</h2>
          <p>
            Refunds are available to any pre-paid member who is unsatisfied with
            their membership to FitLab. The refunded amount will be equal to the
            difference between the initial payment and the remaining balance on
            the account.
          </p>
          <h2>
            16. Copyright or Intellectual Property Infringement Notification
          </h2>
          <p>
            FitLab respects the intellectual property rights of others. You can
            notify FitLab of possible copyright infringement, and FitLab will
            review all claims of copyright infringement received and remove
            content deemed to have been posted or distributed in violation of
            any such laws. To make a claim, please send the details to
            hello@theFitLab.com
          </p>
          <h2>17. Export Controls</h2>
          <p>
            (i) You shall comply with all export laws and restrictions and
            regulations of the Department of Commerce, the United States
            Department of Treasury Office of Foreign Assets Control (ìOFACî), or
            other United States or foreign agency or authority, and not to
            export, or allow the export or re-export of the Material in
            violation of any such restrictions, laws or regulations. By
            downloading or using the Material, you are agreeing to the foregoing
            and you are representing and warranting that you are not located in,
            under the control of, or a national or resident of any restricted
            country or on any such list.
          </p>
          <p>
            (ii) Any software which is downloaded from or made available via
            FitLab for or on behalf of the United States of America, its
            agencies and/or instrumentalities (&quot;U.S. Government&quot;), is
            provided with Restricted Rights. Use, duplication, or disclosure by
            the U.S. Government is subject to restrictions as set forth in
            subparagraph (c)(1)(ii) of the Rights in Technical Data and Computer
            Software clause at DFARS 252.227-7013 or subparagraphs (c)(1) and
            (2) of the Commercial Computer Software - Restricted Rights at 48
            CFR 52.227-19, as applicable. Manufacturer is TSR Gym Technik Ltd.
          </p>
          <h2>18. Miscellaneous</h2>
          <p>
            This Agreement shall be governed by and construed in accordance with
            the laws of the United States. You agree that any legal action or
            proceeding between FitLab and you for any purpose concerning this
            exclusively in a court of competent jurisdiction sitting in Orlando,
            Florida, United States. Any cause of action or claim you may have
            with respect to FitLab must be commenced within one (1) year after
            the claim or cause of action arises. FitLab failure to insist upon
            or enforce strict performance of any provision of this Agreement
            shall not be construed as a waiver of any provision or right. No
            waiver of any term, provision or condition of this Agreement,
            whether by conduct or otherwise, in any one or more instances, shall
            be deemed to be, or shall constitute, a waiver of any other term,
            provision or condition hereof, whether or not similar, nor shall
            such waiver constitute a continuing waiver of any such term,
            provision or condition hereof. No waiver shall be binding unless
            executed in writing by the party making the waiver. This Agreement
            constitutes the complete and exclusive statement of the agreement
            between the parties with respect to the Service and supersedes any
            and all prior or contemporaneous communications, representations,
            statements and understandings, whether oral or written, between the
            parties concerning the Service. If any action in law or in equity is
            necessary to enforce the terms of this Agreement, the prevailing
            party will be entitled to reasonable fees of attorneys, accountants,
            and other professionals, and costs and expenses in addition to any
            other relief to which such prevailing party may be entitled. The
            provisions of this Agreement are severable, and in the event any
            provision hereof is determined to be invalid or unenforceable, such
            invalidity or unenforceability shall not in any way affect the
            validity or enforceability of the remaining provisions hereof. No
            provisions of this Agreement are intended, nor will be interpreted,
            to provide or create any third party beneficiary rights or any other
            rights of any kind in any user, client, customer, affiliate, or any
            party hereto or any other person unless specifically provided
            otherwise herein, and except as so provided, all provisions hereof
            will be personal solely between the parties to this Agreement;
            except that Sections 11, 12 and 13 is intended to benefit FitLab and
            its officers, directors, employees, agents, licensors, suppliers,
            and Information Providers. FitLab may assign its rights and duties
            under this Agreement to any party at any time without notice to you.
          </p>
          <h2>19. Notice</h2>
          <p>
            FitLab may deliver notice to you under this Agreement by means of
            electronic mail, a general notice on FitLab, or by written
            communication delivered by mail to your address on record in
            FitLab&apos;s account information. You may give notice to FitLab at
            any time via electronic mail to FitLab by emailing us at
            hello@theFitLab.com.
          </p>
        </div>
      </div>
    </div>
    <SimpleFooter />
  </LayoutBase>
);

export default Terms;
